import "../src/pages/style/App.css";
import React from "react";
import "./pages/fontawesomeIcons";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Loginform from "./pages/Loginform";
import Signupform from "./pages/Signupform";
import LandingPage from './components/Competence-Pages/LandingPage/LandingPage';
import Forgotpassword from "./pages/password/Forgotpassword";
import Dashboard from "./pages/Dashboard";
import Pricing from "./pages/Pricing";
import Features from "./pages/Features";
import Sustainability from "./pages/Sustainability";
import About from "./pages/About";
import Home from "./pages/Home";
import Navbar from "./components/Navbar/Navbar";
import MyTerms from "./components/Competence-Pages/MyTerms/MyTerms";
import References from "./components/Competence-Pages/References/References";
import Personality from "./components/Competence-Pages/Personality/Personality";
import Profile from "./components/Competence-Pages/Profile/Profile";
import TheFuture from "./components/Competence-Pages/TheFuture/TheFuture";
import Validation from "./components/Competence-Pages/Validation/Validation";
import Video from "./components/Competence-Pages/Video/Video";
import CollaborationRoom from "./components/Competence-Pages/CollaborationRoom/CollaborationRoom";
import Competencies from "./components/Competence-Pages/Competencies/Competencies";
import Updateplan from "./components/MyPlanComponents/Updateplan";
import { useState, useEffect} from "react";
import jwt_decode from 'jwt-decode';
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OurValues from "./pages/OurValues";
import IntelligenceReport from "./pages/IntelligenceReport";
import RetrievePassword from "./pages/password/RetrievePassword";
import ResetPasswordAuthenticate from "./pages/password/ResetPasswordAuthenticate";
import ResetPassword from "./pages/password/ResetPassword";
import IntelligenceThankscard from "./pages/IntelligenceThankscard";
import ThanksCardPayment from "./modals/ThanksCardPayment";
import PersonalData from "./components/Competence-Pages/LandingPage/PersonalData";
import LandingThanksModal from "./components/Competence-Pages/LandingPage/LandingThanksModal";
import CompetenceMapping from './components/Competence-Pages/Competencies/CompetenceMapping';
import Settings from "./components/Settings/Settings";
import Logout from "./components/Settings/Logout";
import Account from "./components/Settings/Account";
import DeletePhoto from "./components/Settings/DeletePhoto";
import DeactivateAccount from "./components/Settings/DeactivateAccount";
import PromotionCode from "./components/Settings/PromotionCode"
import {NotificationProvider} from './components/Navbar/NotificationContext';
import Help from "./pages/Help";


function App() {
  
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  
  const first_login = localStorage.getItem('fLogin')
  const userLogin = localStorage.getItem("user");

  useEffect(() => {
    const u = localStorage.getItem("token");
          u ? setUser(true) : setUser(false);
          try {
            const decoded = jwt_decode(u);
            if (decoded.exp < Date.now() / 1000) {   
            alert("the page login time is expired, Please login again")
            // If the token is expired, navigate user to home page and clear token from local storage
            localStorage.clear();
            window.location.reload()
            navigate('/');
            return;
            }
    
        } catch (error) {
          // If the token is invalid, navigate user to login page and clear token from local storage
            localStorage.clear();
        }

  }, [navigate]);

  
  const landingRoute = (path) => {
    if (first_login === "true") {
      return <Route exact path={path} element={<Navigate to="dashboard/landingPage" />} />;
    } else {
      // Return a different element or null if needed
      return null;
    }
  };

  return (
    <>
     <NotificationProvider>
      <Navbar user={user} />
      <Routes>
        <Route path="/" element={<Home />} />
        {!user && (
          <>
            <Route path="/loginform" element={<Loginform authenticate={() => setUser(true)} />}/>
            <Route path="/signup" element={<Signupform />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route path="/resetPasswordAuthenticate" element={<ResetPasswordAuthenticate authenticate={() => setUser(true)} />} />
            <Route path="/retrievePassword" element={<RetrievePassword authenticate={() => setUser(true)} />} />
            </>)}

            
            <Route path="/intelligencethankscard" element={<IntelligenceThankscard />} />
            <Route path="/intellgencereport" element={<IntelligenceReport />} />

            {/* static route */}
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/features" element={<Features />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/about" element={<About />} />
            <Route path="/termsOfUse" element={<TermsOfUse />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/ourvalues" element={<OurValues />} />
            <Route path="/help" element={<Help />} />

        {user && (
          <>
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/updateplan" element={<Updateplan />} />
            <Route path="/thankscardpayment" element={<ThanksCardPayment />} />
            <Route path="/landingThanksModal" element={<LandingThanksModal/>} />

            {/* route of competence dashboard */}
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="landingPage" element={<LandingPage />} />
              <Route path="profile" element={<Profile authenticate={() => setUser(false)} />} />
              <Route path="competencies" element={<Competencies />} />
              <Route path="competenceMapping" element={<CompetenceMapping />} />
              <Route path="myTerms" element={<MyTerms />} />
              <Route path="references" element={<References />} />
              <Route path="personality" element={<Personality />} />
              <Route path="theFuture" element={<TheFuture />} />
              <Route path="validation" element={<Validation />} />
              <Route path="video" element={<Video />} />
              <Route path="collaborationRoom" element={<CollaborationRoom />} />
              <Route path="personalData" element={<PersonalData />} />
              <Route exact path="*" element={<Navigate to={ (first_login ==="true") ? "landingPage" : "profile"} />} />
            </Route>

            {/* route of  Settings page */}
            <Route path="Settings" element={<Settings />} />
            <Route path="Settings/Logout" element={<Logout />} />
            <Route path="Settings/PromotionCode" element={<PromotionCode />} />
            <Route path="Settings/Account" element={<Account authenticate={() => setUser(false)} />} >
            <Route path="DeactivateAccount" element={<DeactivateAccount />} />
              </Route>
            <Route path="Settings/Delete" element={<DeletePhoto />} />
            


            <Route exact path="*" element={<Navigate to={ (first_login ==="true") ? "dashboard/landingPage" : "dashboard/profile"} />} />
          </>
          )}
          
          {/* for routing landing page */}
          {landingRoute("dashboard/competenceMapping")}
          {landingRoute("dashboard/personalData")}
          {landingRoute("dashboard/myTerms")}
          {landingRoute("dashboard/theFuture")}
          <Route exact path="dashboard/competenceMapping" element={<Navigate to="dashboard/profile" />} />

          <Route exact path="dashboard/landingPage" element={<Navigate to={ (first_login === "true") ? "dashboard/landingPage" : "dashboard/landingPage"} />} />
          <Route exact path="dashboard/profile" element={<Navigate to={ (first_login ==="true")  ? "dashboard/landingPage" :"dashboard/landingPage" } />} />
          <Route exact path="dashboard" element={<Navigate to={ (first_login ==="true")  ? "/dashboard/landingPage" :"/dashboard/profile" } />} />
          { !userLogin && <Route exact path="*" element={<Navigate to={"/"} />} />}
      </Routes>
      </NotificationProvider>
    </>
  );
}

export default App;
